import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
  const iframeUrl = process.env.REACT_APP_STOREFRONT_IFRAME_URL;
  

  useEffect(() => {
    // Function to update iframe height on window resize
    const updateHeight = () => {
      setIframeHeight(window.innerHeight);
    };

    // Set the initial height
    updateHeight();

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const detectInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /FBAN|FBAV|Instagram|Twitter|Snapchat|TikTok/i.test(userAgent);
  };
  

    useEffect(() => {
     
      if (detectInAppBrowser()) {
        window.location.href = `googlechrome://${window.location.hostname}`;
      }
    }, []);

  return (
    <div className="App">
     
      <iframe 
          src={iframeUrl}
          title="Lumi Storefront" 
          width="100%" 
          height={`${iframeHeight}px`}
          style={{ border: 'none' }}
          allowFullScreen
        ></iframe>
    </div>
  );
}

export default App;
